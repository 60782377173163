<template>
  <div class="static-content-wrapper">
    <h1>What is Agility Chimp?</h1>
    <p>
      Agility Chimp gives you the ability to keep digital records for every
      trial and every class you and your dog(s) attend. You will have the
      abililty to add dogs, trials, and classes - and then add a record or score
      card for every class. Having your records digital means no more losing or
      damaging your physical notebook and allows you to track your dog's
      progress.
    </p>
    <p>
      In addition, you will also be able to track your titles and title points
      so you will always know exactly what you need to achieve that next
      milestone.
    </p>
    <p>
      Our easy to use interface allows you to find trials and classes quickly so
      you can view or add your results in just a few clicks.
    </p>

    <p>
      We are currently in BETA release and are accepting a limited number of
      people to use and help us improve Agility Chimp. If you are interested in
      becoming a BETA user, please
      <a class="secondary" href="mailto:gkruel@me.com" target="_blank"
        >send us an email</a
      >
      and we will walk you through the signup process along with giving a
      one-on-one session to go over the app with you.
    </p>
    <p>
      Please be aware that BETA release means the app will be functioning, but
      may have bugs or items that are not working correctly.
    </p>
    <h1 style="margin-top: 40px">Continued Improvement</h1>
    <p>
      At Agility Chimp, we are constantly working to improve functionality and
      add features to our app and view this as a 'community' where all ideas are
      welcome. If you have an idea for a feature, enhancement, or have come
      across something that is not working properly, we want to hear from you!
      Simply
      <a class="secondary" href="mailto:gkruel@me.com" target="_blank"
        >send us an email</a
      >
      with your feature request or bug fix, and we will get back to you as soon
      as possible. Not only will you help shape the future of Agility Chimp, but
      your idea may be the next big thing everyone is talking about.
    </p>
  </div>
</template>
